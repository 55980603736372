<template>
  <div class="sendingManagement">
    <div>
      <el-button type="primary"
                 v-if="!reviews"
                 @click="sendDocument">发送公文</el-button>
      <el-button type="info"
                 @click="down">导出</el-button>
      <el-button type="info"
                 @click="downWord">导出文档</el-button>
    </div>
    <div class="header-div">
      <el-select v-model="queryFileType"
                 class="header-query"
                 clearable
                 placeholder="请选择文件类型">
        <el-option v-for="file in filesTypeList"
                   :key="file.value"
                   :label="file.label"
                   :value="file.value"></el-option>
      </el-select>
      <el-select v-model="queryState"
                 class="header-query"
                 placeholder="请选择状态">
        <el-option v-for="option in stateList"
                   :key="option.value"
                   :label="option.label"
                   :value="option.value"></el-option>
      </el-select>
      <el-date-picker v-model="queryTime"
                      type="daterange"
                      class="header-query"
                      range-separator="至"
                      value-format="yyyy-MM-dd"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
      </el-date-picker>
      <el-input placeholder="请选择标题"
                class="header-query"
                v-model="queryTitle"></el-input>
      <el-button type="primary"
                 @click="loadReceiving(1)">查询</el-button>
    </div>
    <el-table :data="receivingList">
      <el-table-column label="文件标题"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_urgent===1?'danger':'info'">{{scope.row.is_urgent===1?'紧急':'正常'}}</el-tag>
          {{scope.row.title}}
        </template>
      </el-table-column>
      <el-table-column label="收文日期"
                       prop="opertime"
                       align="center"></el-table-column>
      <el-table-column label="收文类型"
                       prop="typeStr"
                       align="center"></el-table-column>
      <el-table-column label="状态"
                       align="center">
        <template slot-scope="scope">
          <el-tag>{{scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       :width="reviews?'380px':'300px'"
                       align="center">
        <template slot-scope="scope">
          <div v-if="reviews">
            <el-button type="warning"
                       size="mini"
                       v-if="scope.row.status!==1&&scope.row.status!==3"
                       :disabled="scope.row.status!==0"
                       @click="detailReceiving(scope.row,2)">审 核</el-button>
            <el-button type="info"
                       size="mini"
                       @click="modifyReceiving(scope.row,1)">编 辑</el-button>
            <el-button type="success"
                       size="mini"
                       v-if="scope.row.status===1||scope.row.status===3"
                       @click="viewSpeedOfProgress(scope.row.id)">进 度</el-button>
            <el-button type="info"
                       size="mini"
                       v-if="scope.row.status===1||scope.row.status===3"
                       @click="detailReceiving(scope.row,3)">查 看</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="downFile(scope.row)">下 载</el-button>
            <el-button size="mini"
                       :disabled="scope.row.status===4"
                       @click="revoke(scope.row.id)">撤 回</el-button>
          </div>
          <div v-if="!reviews">
            <el-button type="info"
                       size="mini"
                       @click="modifyReceiving(scope.row,0)">编 辑</el-button>
            <el-button type="warning"
                       size="mini"
                       @click="detailReceiving(scope.row,1)">查 看</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="downFile(scope.row)">下 载</el-button>
            <el-button size="mini"
                       :disabled="scope.row.status===4"
                       @click="revoke(scope.row.id)">撤 回</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadReceiving"></el-pagination>
    <el-dialog :visible.sync="detailReceivingVisible"
               width="700px"
               :title="receivingForm.id?'查看公文':'添加公文'">
      <el-form ref="receivingForm"
               class="receivingForm"
               :model="receivingForm"
               label-width="110px">
        <el-form-item label="文件标题"
                      prop="title">
          <el-input type="textarea"
                    autosize
                    :disabled="reviews||viewDisable"
                    v-model="receivingForm.title"></el-input>
        </el-form-item>
        <el-form-item label="来文单位"
                      prop="unit">
          <el-input type="textarea"
                    autosize
                    :disabled="reviews||viewDisable"
                    v-model="receivingForm.unit"></el-input>
        </el-form-item>
        <el-form-item label="主要内容"
                      prop="content">
          <el-input type="textarea"
                    autosize
                    :disabled="reviews||viewDisable"
                    v-model="receivingForm.content"></el-input>
        </el-form-item>
        <el-form-item label="办公室意见"
                      prop="proposal">
          <el-input type="textarea"
                    :disabled="reviews||viewDisable"
                    autosize
                    v-model="receivingForm.proposal"></el-input>
        </el-form-item>
        <el-form-item label="文件来源"
                      prop="type">
          <el-select v-model="receivingForm.type"
                     :disabled="reviews||viewDisable"
                     class="header-query"
                     placeholder="请选择文件类型">
            <el-option v-for="file in filesTypeList"
                       :key="file.value"
                       :label="file.label"
                       :value="file.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="完成时间"
                      prop="time">
          <el-date-picker v-model="receivingForm.time"
                          :disabled="reviews||viewDisable"
                          value-format="yyyy-MM-dd"
                          style="padding-left: 5px"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否紧急"
                      prop="ifUrgent"><template>
            <el-switch v-model="receivingForm.ifUrgent"
                       :disabled="reviews||viewDisable"
                       active-text="是"
                       style="padding-left:5px"
                       inactive-text="否">
            </el-switch>
          </template></el-form-item>
        <!-- <el-form-item label="是否归档"
                      prop="ifFile"><template>
            <el-switch v-model="receivingForm.ifFile"
                       style="padding-left:5px"
                       :disabled="reviews||viewDisable"
                       active-text="是"
                       inactive-text="否">
            </el-switch>
          </template></el-form-item> -->
        <el-form-item label="添加文件"
                      prop="file">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :on-remove="onRemoveFile"
                     ref="upload"
                     :disabled="viewDisable"
                     :on-success="enclosureUploadSuccess"
                     :data="{path:'sendingManagement',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       :disabled="viewDisable"
                       type="primary">点击上传</el-button>
          </el-upload>
          <el-button type="primary"
                     size="small"
                     class="load-file"
                     @click="loadFile(receivingForm.file)"
                     v-if="receivingForm.file">下载附件</el-button>
        </el-form-item>
        <el-form-item label="接收人"
                      v-if="reviews"
                      prop="receiverList">
          <el-tag v-for="receiver in receivingForm.receiverList"
                  closable
                  @close="receiverClose(receiver.id)"
                  :key="receiver.id">{{receiver.label}}
          </el-tag>
          <el-button icon="el-icon-plus"
                     size="small"
                     :disabled="principalViewDisable"
                     @click="receiverClick"></el-button>
        </el-form-item>
        <el-form-item label="校长批示"
                      v-if="!principal||viewDisable"
                      prop="bureauLeadersRemark">
          <el-input type="textarea"
                    autosize
                    :disabled="!reviews||principalViewDisable"
                    v-model="receivingForm.bureauLeadersRemark"></el-input>
        </el-form-item>
        <el-form-item label="分管领导批示"
                      v-if="viewDisable"
                      prop="leaderInChargeRemark">
          <el-input type="textarea"
                    autosize
                    disabled
                    v-model="receivingForm.leaderInChargeRemark"></el-input>
        </el-form-item>
        <el-form-item label="部门办理意见"
                      v-if="viewDisable"
                      prop="departmentHandlingOpinions">
          <el-input type="textarea"
                    autosize
                    disabled
                    v-model="receivingForm.departmentHandlingOpinions"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           v-if="!receivingForm.id">
        <el-button @click="detailReceivingVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="replyReceivingConfirm">确 定</el-button>
      </div>
      <div slot="footer"
           v-if="reviews&&!principalViewDisable">
        <el-button @click="correctionReceiving(2)">拒 绝</el-button>
        <el-button type="primary"
                   @click="correctionReceiving(1)">同 意</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="receiverVisible"
               title="请选择接收人"
               width="600px">
      <el-tree :data="teacherList"
               expand-on-click-node
               class="tree-box"
               :default-expanded-keys="defaultExpandedKeys"
               ref="receiverTree"
               @check="receiverCheck"
               accordion
               node-key="key"
               show-checkbox></el-tree>
      <el-button @click="receiverVisible=false">取消</el-button>
      <el-button type="primary"
                 @click="receiverConfirm">确定
      </el-button>
    </el-dialog>
    <el-dialog :visible.sync="fileVisible"
               title="查看文件"
               width="600px">
      <el-table :data="downFileTable">
        <el-table-column label="文件类型"
                         align="center"
                         prop="type">
        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button type="primary"
                       :disabled="!scope.row.fileUrl"
                       @click="loadFile(scope.row.fileUrl)">下载文件</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="speedOfProgressVisible"
               title="查看进度"
               width="600px">
      <div class="speedOfProgressTable">
        <el-table :data="speedOfProgressTable">
          <el-table-column label="接收人"
                           align="center"
                           prop="mname">
          </el-table-column>
          <el-table-column label="进度"
                           prop="statusStr"
                           align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="editReceivingFormVisible"
               title="编辑"
               width="600px">
      <el-form ref="editReceivingForm"
               :model="editReceivingForm"
               label-width="110px">
        <el-form-item :label="editReceivingForm.type"
                      prop="title">
          <el-input type="textarea"
                    autosize
                    v-model="editReceivingForm.content"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editReceivingFormVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="editReceivingFormConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "sendingManagement",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      page: { currentPage: 0, pageCount: 1 },
      queryTime: "",
      queryTitle: "",
      queryFileType: "",
      receivingList: [],
      stateList: [
        { value: "", label: "全部" },
        { value: 0, label: "发布成功" },
        { value: 1, label: "进行中" },
        { value: 2, label: "审核不通过" },
        { value: 3, label: "已存档" },
      ],
      filesTypeList: [
        { value: 1, label: "政策文件" },
        { value: 2, label: "事务文件" },
      ],
      queryState: "",
      detailReceivingVisible: false,
      fileVisible: false,
      receivingForm: {
        title: "",
        unit: "",
        content: "",
        id: "",
        proposal: "",
        bureauLeadersRemark: "",
        leaderInChargeRemark: "",
        departmentHandlingOpinions: "",
        type: "",
        file: "",
        fileUrl: "",
        fileName: "",
        ifFile: false,
        ifUrgent: false,
        time: "",
        receiverList: [],
      },
      teacherList: [],
      currentReceiverList: [],
      receiverVisible: false,
      reviews: false, //是否为校长
      downFileTable: [],
      defaultExpandedKeys: [], //打开接收人层数
      viewDisable: false, //是否主管查看
      principalViewDisable: false, //是否校长查看
      token: sessionStorage.getItem("token"),
      principal: false, //校长是否可见
      speedOfProgressVisible: false,
      speedOfProgressTable: [],
      editReceivingFormVisible: false,
      editReceivingForm: {
        type: "办公室意见",
        content: "",
        did: 0,
      },
      editType: 0,
    };
  },
  created() {
    this.loadReceiving(1);
    this.loadTeacherList();
    //查询用户是否为校长
    this.$post("/media/queryManagerByJob.do", { job: "校长" })
      .then((res) => {
        if (this.userInfo.userid === res.data.id) {
          this.reviews = true;
        }
      })
      .catch((err) => {
        this.$message({ type: "warning", message: err.message });
      });
  },
  methods: {
    loadReceiving(page) {
      const that = this;
      let data = {
        indexNo: page,
        status: this.queryState,
        type: this.queryFileType,
      };
      if (this.queryTitle) {
        data.title = this.queryTitle;
      }
      if (this.queryTime) {
        data.start_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      this.$post("/base/queryDocumentByZg.do", data)
        .then((res) => {
          that.page.currentPage = res.data.indexNo;
          that.page.pageCount = res.data.pageCount;
          that.receivingList = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadTeacherList() {
      const that = this;
      this.$post("/media/queryBookByTea.do", {})
        .then((res) => {
          res.data[0].children.forEach((department) => {
            this.defaultExpandedKeys.push(department.key);
          });
          this.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    sendDocument() {
      this.detailReceivingVisible = true;
      this.viewDisable = false;
      this.$nextTick(() => {
        this.$refs["receivingForm"].resetFields();
        this.receivingForm.id = "";
        this.receivingForm.fileUrl = "";
        this.receivingForm.fileName = "";
        this.receivingForm.proposal = "";
        this.receivingForm.bureauLeadersRemark = "";
        this.receivingForm.leaderInChargeRemark = "";
        this.receivingForm.departmentHandlingOpinions = "";
        this.$refs.upload.clearFiles();
      });
    },
    detailReceiving(row, type) {
      //type=1主管查看，2校长审核，3校长查看
      if (type === 1) {
        this.principal = true;
        this.viewDisable = true;
      } else if (type === 2) {
        this.receivingForm.receiverList = [];
        this.viewDisable = false;
        this.principal = false;
        this.principalViewDisable = false;
      } else {
        this.principal = false;
        this.viewDisable = true;
        this.principalViewDisable = true;
      }
      this.detailReceivingVisible = true;
      this.loadHistory(row.id);
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
        this.receivingForm.title = row.title;
        this.receivingForm.unit = row.unit;
        this.receivingForm.type = row.type;
        this.receivingForm.content = row.content;
        this.receivingForm.id = row.id;
        this.receivingForm.ifFile = row.status === 3;
        this.receivingForm.ifUrgent = row.is_urgent === 1;
        this.receivingForm.time = row.end_time;
        this.receivingForm.file = row.fileUrl;
      });
    },
    replyReceivingConfirm() {
      const that = this;
      let data = {
        unit: this.receivingForm.unit,
        title: this.receivingForm.title,
        content: this.receivingForm.content,
        type: this.receivingForm.type,
        end_time: this.receivingForm.time,
        is_urgent: this.receivingForm.ifUrgent ? 1 : 2,
        fileName: this.receivingForm.fileName,
        fileUrl: this.receivingForm.fileUrl,
        opinion: this.receivingForm.proposal,
      };
      this.$confirm("是否确认提交该申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post("/base/addDocument.do", data)
          .then((res) => {
            that.$message({ type: "success", message: res.message });
            that.loadReceiving(1);
            that.detailReceivingVisible = false;
          })
          .catch((err) => {
            that.detailReceivingVisible = false;
            that.$message({ type: "warning", message: err.message });
          });
      });
    },
    receiverClick() {
      this.receiverVisible = true;
      this.$nextTick(() => {
        this.$refs.receiverTree.setCheckedKeys([]);
        this.$refs.receiverTree.setCheckedNodes(this.currentReceiverList);
      });
    },
    receiverCheck(row, rowArray) {
      this.currentReceiverList = [];
      rowArray.checkedNodes.forEach((checked) => {
        if (checked.type === 1) {
          this.currentReceiverList.push(checked);
        }
      });
    },
    receiverConfirm() {
      this.$nextTick(() => {
        this.currentReceiverList = [];
        this.currentReceiverList = this.$refs.receiverTree.getCheckedNodes();
      });
      this.receivingForm.receiverList = this.currentReceiverList;
      this.receiverVisible = false;
    },
    receiverClose(receiverId) {
      this.receivingForm.receiverList.forEach((receiver, index) => {
        if (receiver.id === receiverId) {
          this.receivingForm.receiverList.splice(index, 1);
        }
      });
      this.currentReceiverList = this.receivingForm.receiverList;
    },
    //校长审核
    correctionReceiving(state) {
      let teacherIdList = [];
      this.receivingForm.receiverList.forEach((teacher) => {
        teacherIdList.push(teacher.id);
      });
      let data = {
        status: state,
        opinion1: this.receivingForm.bureauLeadersRemark,
        fileName: this.receivingForm.fileName,
        fileUrl: this.receivingForm.fileUrl,
        teachers: teacherIdList.join(","),
        id: this.receivingForm.id,
      };
      this.$confirm("是否确认提交该申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/reviewDocument.do", data)
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.loadReceiving(1);
              this.detailReceivingVisible = false;
            })
            .catch((err) => {
              this.detailReceivingVisible = false;
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消确认" });
        });
    },
    downFile(row) {
      this.fileVisible = true;
      this.downFileTable = [];
      let file = {};
      let file1 = {};
      file.type = "主管";
      file.fileUrl = row.fileUrl; //主管文件
      file1.type = "校长";
      file1.fileUrl = row.fileUrl1; //校长文件
      this.downFileTable[0] = file;
      this.downFileTable[1] = file1;
    },
    // 撤回
    revoke(id) {
      this.$confirm("是否确认撤回？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/cancelDocument.do", { id })
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.loadReceiving(1);
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消撤回" });
        });
    },
    enclosureUploadSuccess(res, file) {
      if (this.receivingForm.fileName) {
        this.receivingForm.fileName = `${this.receivingForm.fileName},${file.name}`;
      } else {
        this.receivingForm.fileName = file.name;
      }
      if (this.receivingForm.fileUrl) {
        this.receivingForm.fileUrl = `${this.receivingForm.fileUrl},${file.response.message}`;
      } else {
        this.receivingForm.fileUrl = file.response.message;
      }
    },
    loadFile(url) {
      let urlList = url.split(",");
      if (urlList.length === 1) {
        window.open(this.$fileUploadDomain + url);
      } else {
        this.downZip(urlList);
      }
    },
    // 批量下载
    downZip(urlList) {
      const that = this;
      this.$postFile("/base/ZipOss.do", urlList)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.receivingForm.fileUrl = "";
      this.receivingForm.fileName = "";
      if (file.length !== 0) {
        file.forEach((newFile) => {
          if (this.receivingForm.fileName) {
            this.receivingForm.fileName = `${this.receivingForm.fileName},${newFile.name}`;
          } else {
            this.receivingForm.fileName = newFile.name;
          }
          if (this.receivingForm.fileUrl) {
            this.receivingForm.fileUrl = `${this.receivingForm.fileUrl},${newFile.response.message}`;
          } else {
            this.receivingForm.fileUrl = newFile.response.message;
          }
        });
      }
    },
    down() {
      const that = this;
      let paramas = new FormData();
      paramas.append("status", this.queryState);
      paramas.append("title", this.queryTitle);
      paramas.append("type", this.queryFileType);
      if (this.queryTime[0]) {
        paramas.append("start_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      this.$postFile("/base/exportDocumentByZg.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    downWord() {
      const that = this;
      let paramas = new FormData();
      paramas.append("status", this.queryState);
      paramas.append("title", this.queryTitle);
      paramas.append("type", this.queryFileType);
      if (this.queryTime[0]) {
        paramas.append("start_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      this.$postFile("/base/exportDocumentZip.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/msword",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出文件";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 校长查看进度
    viewSpeedOfProgress(id) {
      this.speedOfProgressVisible = true;
      this.$post("/base/queryDocumentAllPerson.do", { did: id })
        .then((res) => {
          this.speedOfProgressTable = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    modifyReceiving(row, type) {
      this.editReceivingFormVisible = true;
      this.editReceivingForm.did = row.id;
      this.editType = type;
      if (type === 1) {
        this.editReceivingForm.type = "校长意见";
      }
      this.editReceivingForm.content = "";
    },
    editReceivingFormConfirm() {
      let data = {
        did: this.editReceivingForm.did,
        type: this.editType, //0办公室意见1校长意见2分管领导批示3部门办理意见
        opinion: this.editReceivingForm.content,
      };
      if (this.editReceivingForm.type === "部门办理意见") {
        data.type = 3;
      }
      this.$confirm("是否确认修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/UpdateOpinionByDid.do", data)
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.loadReceiving(1);
              this.editReceivingFormVisible = false;
            })
            .catch((err) => {
              this.editReceivingFormVisible = false;
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消确认" });
        });
    },
    loadHistory(id) {
      this.$post("/base/queryOpinionListByDid.do", { did: id })
        .then((res) => {
          this.receivingForm.proposal = "";
          this.receivingForm.bureauLeadersRemark = "";
          this.receivingForm.leaderInChargeRemark = "";
          this.receivingForm.departmentHandlingOpinions = "";
          res.data.forEach((row) => {
            if (row.type === 0) {
              if (row.list) {
                this.receivingForm.proposal = row.list.join("\n");
              }
            } else if (row.type === 1) {
              if (row.list) {
                this.receivingForm.bureauLeadersRemark = row.list.join("\n");
              }
            } else if (row.type === 2) {
              if (row.list) {
                this.receivingForm.leaderInChargeRemark = row.list.join("\n");
              }
            } else if (row.type === 3) {
              if (row.list) {
                this.receivingForm.departmentHandlingOpinions =
                  row.list.join("\n");
              }
            }
          });
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.receivingForm {
  height: 500px;
  overflow-x: auto;
  padding: 0 50px 0 0;
}
.header-query {
  margin: 0 5px;
}
.header-div {
  margin-top: 20px;
}
.speedOfProgressTable {
  height: 500px;
  overflow-y: auto;
}
.tree-box {
  height: 500px;
  overflow-y: auto;
}
</style>
